<template>
    <div>
        <vue-element-loading
        :active="appLoading"
        spinner="bar-fade-scale"
        color="#4480C5"
        size="60"
        is-full-screen
      />
      <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #ffffff">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #ffffff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
    
    
      <v-layout wrap justify-center>
        <v-flex pt-5 xs11 sm11 md11 lg11 xl11 >
    
          <!-- Header Section -->
          <v-layout wrap justify-start class="my-3">
            <v-flex xs12 sm3 md3 lg10 text-start align-center pt-2 class="headingChurch">Pending Church List</v-flex>
            <!-- <v-flex xs12 sm9 md9 lg2 text-end align-center ><v-btn class="churchbtn" color="blue" style="color:white"  @click="goToAddChurch">ADD CHURCH</v-btn></v-flex> -->
          </v-layout>
    
        
          <v-layout wrap justify-start v-if="pendingchurchlist.length>0">
            <v-flex xs12>
         
      
                <!-- Table section -->
                <v-layout wrap justify-center>
                  <v-flex xs12>
                    <v-data-table
                   :headers="headers"
                    :items="pendingchurchlist"
                    hide-default-footer
                    class="elevation-1 text--black"
                    id="virtual-scroll-table"
                    @click:row="redirectToViewPage"
                    style="cursor: pointer;"
                  >
                    <!-- <template v-if="userRole == 'admin'" v-slot:[`item._id`]="{ item }">
                      <v-icon small color="red" class="ml-4" @click.stop="openDeleteDialog(item)">
                        mdi-delete
                      </v-icon>
                    </template> -->
                  </v-data-table>
                  </v-flex>
                </v-layout>
                <!-- pagination -->
                <v-layout wrap justify-center pt-2>
                  <v-flex xs12>
                    <div class="text-center pb-5" v-if="pages > 1">
                      <v-pagination :length="pages" v-model="currentPage" color="primary white--text"
                        circle></v-pagination>
                    </div>
                  </v-flex>
                </v-layout>
             
            </v-flex>
          </v-layout>

          <v-layout v-else>
            <v-flex xs12 text-center py-5 style="
                font-family: intersemibold;
                color: grey;
                font-size: 13px;
              ">
              No Pending Churches
            </v-flex>
          </v-layout>
          </v-flex>
          </v-layout>
          
          
        </div>
    </template>
    
    <script>
    import axios from 'axios';
    export default {
      data() {
        return {
       
          showSnackBar:false,
        timeout:5000,
        msg: "",
          deleteDialog: false, // Controls the dialog visibility
          appLoading: false,
          pendingchurchlist:[],
        
          headers: [
          { text: "Name", value: "name", width: "250px" },
          { text: "Rite", value: "rite.name", width: "130px" },
          { text: "Diocese", value: "diocese.name", width: "10px" },
          { text: "Location", value: "address.city", width: "90px" },
          { text: "Country", value: "address.country", width: "110px" },
          { text: "Added By", value: "added_by.name", width: "90px" },
        ],
          page: 1,
          currentPage: 1,
          pages: 0,
          limit: 10,
        };
      },
      watch:{
  
        currentPage() {
          this.getData();
        },
       
      
      },
     
      mounted() {
            this.getData();
           
            
        },
      methods: {
        redirectToViewPage(item) {
          this.$router.push('/churchView?id=' + item._id);
        },
      
  
      
        getData() {
                this.appLoading = true;
             //   const currentTime = new Date().toISOString(); // Get current time in ISO format
                axios({
                    url: "/subadmins/pending/church/list",
    
                    method: "GET",
                    headers: {
                        token: localStorage.getItem("token"),
                    },
                    params: {
                      limit: this.limit,
                      page: this.currentPage,
    
                    },
                })
                    .then((response) => {
                        this.appLoading = false;
                        this.pendingchurchlist = response.data.data;
                        // this.pages = response.data.pages;
                        // this.totalData = response.data.count;
                        this.pages = Math.ceil(response.data.totalLength / this.limit);
                    })
                    .catch((err) => {
                        this.appLoading = false;
                        this.ServerError = true;
                        console.log(err);
                    });
            },
      },
     
    };
    </script>
    
    <style scoped>
       
       
    .dialog-card {
      font-family: interbold;
      border-radius: 8px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    }
    
    .dialog-icon {
      animation: pulse 1s infinite alternate;
    }
    
    .dialog-button {
      min-width: 120px;
    }
    
    @keyframes pulse {
      from {
        transform: scale(1);
        opacity: 0.7;
      }
      to {
        transform: scale(1.1);
        opacity: 1;
      }
    }
    </style>
    